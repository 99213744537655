@use '../../styleguide/vars.scss';
@use '../../styleguide/colors.scss';

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;

  h3,
  p {
    margin-bottom: 30px;
  }

  p {
    text-align: center;
  }

  svg {
    margin-bottom: 30px;
    margin-top: 30px;

    @media screen and (min-width: vars.$medium) {
      width: 75%;
    }

    @media screen and (min-width: vars.$large) {
      width: 350px;
    }

    @media screen and (min-width: vars.$extra-large) {
      width: 500px;
    }

    @media (orientation: landscape) and (min-aspect-ratio: 3 / 2 ) {
      width: 200px;
      margin: 10px 0 0 0;
    }
  }
}

.button {
  width: 100%;
}

.title {
  text-align: center;
}

.goHomeLink {
  color: colors.$white;
  text-decoration: none;
  margin-bottom: 30px;
}
