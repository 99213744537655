@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.floatingButton {
  cursor: pointer;
  font-size: 20px;
  height: 50px;
  width: 50px;
  background: white;
  border-radius: 50%;
  opacity: 0;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  display: block;
  z-index: 1;
  position: fixed;

  &:focus {
    outline: 0;
  }

  @media screen and (min-width: vars.$large) {
    height: 60px;
    width: 60px;
  }

  > svg {
    text-align: center;
    width: 100%;
    max-width: 60px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
  }
}

.floatingButtonLarge {
  height: 245px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);

  > svg {
    position: relative;
    top: unset;
    left: unset;
    transform: unset;
  }
}

.floatingButtonSafari {
  height: 195px;
}

.floatingButtonShare {
  ul li a {
    svg {
      margin-top: 10px;
    }
  }
}

.circleLine {
  stroke: colors.$background;
}

.circle {
  stroke: colors.$primary-color;
}
