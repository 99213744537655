@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.dailiesOpener {
  display: none;
  cursor: pointer;

  @media screen and (min-width: vars.$extra-large) {
    display: block;
    left: -60px;
    position: fixed;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    z-index: 2;

    &:hover {
      left: -40px;
    }
  }
}

.dailiesBar {
  margin-bottom: 30px;

  @media screen and (min-width: vars.$extra-large) {
    padding: 0 30px;
  }
}

.imageContainer {
  align-items: center;
  border-right: 1px solid colors.$grey;
  display: flex;
  justify-content: center;
  min-width: 70px;
  max-width: 70px;
  padding-right: 10px;

  img {
    width: 100%;
  }
}

.dailySection {
  margin-top: 30px;

  h3 {
    display: flex;
    font-weight: bold;
    font-stretch: condensed;
    margin-bottom: 15px;

    img {
      margin-right: 10px;
      width: 30px;
    }
  }
}

.daily {
  background-color: colors.$white;
  border: 10px solid colors.$white;
  display: flex;
  flex-direction: row;
  height: 95px;
}

.dailyButton {
  display: none;
  pointer-events: none;
  position: absolute;
  text-decoration: none;
  top: 50%;
  transform: translateY(-50%);
  width: calc(100% - 10px);
}

.postContent {
  padding-left: 10px;
  position: relative;
  width: 100%;

  h4 {
    font-size: 1rem;
    font-stretch: condensed;
    font-weight: bold;
  }

  p {
    color: colors.$secondary-text;
    display: -webkit-box;
    overflow: hidden;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  &:focus,
  &:hover {
    .dailyButton {
      display: block;
      pointer-events: initial;
    }

    p,
    h4 {
      opacity: 0;
    }
  }
}

.scroll {
  overflow: scroll;

  @media screen and (min-width: vars.$extra-large) {
    overflow: initial;
  }
}
